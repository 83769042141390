import axios, { baseURL } from "../axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangContext } from "../Contexts/Context";

const Sponsor = () => {
  const [sponsors, setsponsors] = useState([]);
  const { t, i18n } = useTranslation();
  const { lang, locale, setLocale } = useContext(LangContext);

  useEffect(() => {
    axios
      .get("sponsors")
      .then((res) => {
        setsponsors(res.data.data.OurSponsors);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div>
        <div id="top">
          <img src="./img/top1.png" alt="" />
        </div>
        <header>
          <main
            className="site-section bg-image sp"
            style={{
              backgroundImage: 'url("./img/sponsor_2.jpg")',
              backgroundAttachment: "fixed",
            }}
          >
            <section>
              {/* nadpis */}
              <div className="col-lg-12 ">
                <h2 className="text-center text-white wow fadeInDown">
                  {t("sponsors")}
                </h2>
                <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
                  <hr />
                </div>
              </div>
            </section>
            <div className="container">
              <div className="content">
                {sponsors.slice(0, 4).map((item, index) => {
                  return (
                    <div className="col_1">
                      <h4 className="wow zoomIn">
                        {locale == "uz" && item.title_uz}
                        {locale == "ru" && item.title_ru}
                        {locale == "en" && item.title_en}
                      </h4>
                      <div className="spons_text">
                        <p className="wow fadeInRight">
                          {locale == "uz" && item.text_uz}
                          {locale == "ru" && item.text_ru}
                          {locale == "en" && item.text_en}
                        </p>
                        {/* <a href="https://tashkentzoo.uz/" target="_blank"> <button type="button" className="wow fadeInRight">Открыть</button></a> */}
                      </div>
                      <img
                        className="wow fadeInRight"
                        src={`${baseURL}uploads/${item.image}`}
                        style={{ borderRadius: "100%", height: "200px" }}
                        alt=""
                      />
                    </div>
                  );
                })}
                {/* <div className="col_1">
                  <h4 className="wow zoomIn">Zoo Tashkent</h4>
                  <div className="spons_text">
                    <p className="wow fadeInRight">Ждем Вас в нашем маленьком чудесном мире, где собраны лучшие питомцы, от самых малых до самых больших!</p>
                    <a href="https://tashkentzoo.uz/" target="_blank"> <button type="button" className="wow fadeInRight">Открыть</button></a>
                  </div>
                  <img className="wow fadeInRight" src="./img/sponsor_1.png" alt="" />
                </div>
                <div className="col_1">
                  <h4 className="wow zoomIn">Zoo Tashkent</h4>         
                  <div className="spons_text">
                    <p className="wow fadeInRight">Ждем Вас в нашем маленьком чудесном мире, где собраны лучшие питомцы, от самых малых до самых больших!</p>
                    <a href="https://tashkentzoo.uz/" target="_blank"> <button type="button" className="wow fadeInRight">Открыть</button></a>
                  </div>
                  <img className="wow fadeInRight" src="./img/sponsor_1.png" alt="" />
                </div> */}
              </div>
            </div>
            <div className="top_sponsor"></div>
            {/* slayder ========================= */}
          </main>
          {/* FOOTER================================================================ */}
          <div id="loader-wrapper">
            <div id="loader" />
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
          </div>
        </header>
      </div>
    </>
  );
};
export default Sponsor;
