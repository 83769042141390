import logo from "./logo.svg";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";

// import { useLocation } from "react-router-dom";
import Index from "./pages/index";
import About from "./pages/about";
import Sponsor from "./pages/sponsor";
import Galery from "./pages/galarey";
import Erasmus from "./pages/erasmus";
import ErasmusModul from "./pages/events/modul";
import ErasmusActivities from "./pages/events/activities";
import ErasmusPortners from "./pages/events/portners";
import ErasmusPurpose from "./pages/events/purpose";
import ErasmusTask from "./pages/events/task";
import Contact from "./pages/contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import OneGallery from "./pages/onePage/oneGallery";
import OneActivity from "./pages/onePage/oneActivity";
import { useEffect } from "react";
import Modal from "./components/modal";
function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [localStorage.getItem("lang")]);
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/sponsor" element={<Sponsor />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/galery" element={<Galery />} />
        <Route exact path="/erasmus" element={<Erasmus />} />
        <Route exact path="/erasmus/modul" element={<ErasmusModul />} />
        <Route
          exact
          path="/erasmus/activities"
          element={<ErasmusActivities />}
        />
        <Route exact path="/erasmus/portners" element={<ErasmusPortners />} />
        <Route exact path="/erasmus/purpose" element={<ErasmusPurpose />} />
        <Route exact path="/erasmus/task" element={<ErasmusTask />} />
        <Route exact path="/galery/:id" element={<OneGallery />} />

        <Route exact path="/erasmus/activities/:id" element={<OneActivity />} />

        <Route exact path="/erasmus/activities/:id" element={<OneActivity />} />
        <Route path="/modal" element={<Modal />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
