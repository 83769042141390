import axios, { baseURL } from "../axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function About() {
  const { t, i18n } = useTranslation();
  const [teams, seteams] = useState([]);

  useEffect(() => {
    axios
      .get("teams")
      .then((res) => {
        seteams(res.data.data.Team);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div>
        <header>
          {/* Видео ==================== */}
          <div className="video">
            <video src="./video/Meeting - 1035.mp4" autoPlay loop muted />
            <h1 className="wow flipInX lang" id="dobro">
              {t("about-p-welcome")}
            </h1>
          </div>
        </header>
        <main>
          {/* Цель ================================ */}
          <section className="section_2">
            <div className="site-section_1">
              <div className="w-50 mx-auto">
                <hr />
              </div>
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 wow fadeInLeft">
                      <p className="mb-5">
                        <img
                          src="./img/about_2.jpg"
                          alt="Image"
                          className="img-fluid rounded"
                        />
                      </p>
                    </div>
                    <div className="col-lg-5 ml-auto ">
                      <h2
                        className="site-section-heading text-black mb-3 wow fadeInDown lang"
                        id="sel"
                      >
                        {" "}
                        {t("about-p-aim")}{" "}
                      </h2>
                      <p
                        className="text-black wow fadeInRight land"
                        id="mushtoq1"
                      >
                        {" "}
                        <span style={{ color: "red" }}>
                          {t("about-desc-title")}
                        </span>{" "}
                        {t("aim-desc")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50 mx-auto">
              <hr />
            </div>
          </section>
          {/* команда  ===================== */}
          <section className="reviews section_2">
            <div className="container ">
              <div className="container py-5">
                <div className="row text-center">
                  <div className="col-lg-8 mx-auto section_6">
                    <h2 w className="wow fadeInDown">
                      {t("team")}
                    </h2>
                    <div className="w-50 mx-auto">
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
              <div className="container">
                <div className="row text-center">
                  {/* Team item */}
                  {teams.map((team, index) => (
                    <div className="col-12 col-md-6 mb-5 wow fadeInLeft">
                      <div className="bg-white rounded shadow-sm py-5 px-4">
                        <img
                          src={`${baseURL}uploads/${team.image}`}
                          alt=""
                          width={100}
                          className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                        />
                        <h6 className="mb-0">{team.name_en}</h6>
                        <span className="small text-uppercase text-muted">
                          {team.role_en}
                        </span>
                        <ul className="social mb-0 list-inline mt-0 icon">
                          <div className="black_circle_min">
                            <a href>
                              <img src="./img/f.png" alt="" />
                            </a>
                            <a href>
                              <img src="./img/i.png" alt="" />
                            </a>
                            <a href>
                              <img src="./img/t.png" alt="" />
                            </a>
                          </div>
                        </ul>
                      </div>
                    </div>
                  ))}
                  {/* End */}
                  {/* <div className="col-12 col-md-6 mb-5 wow fadeInRight">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src="./img/t2.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                      <h6 className="mb-0">Azizbek Khaitov </h6><span className="small text-uppercase text-muted">Специалист по организационным делам</span>
                      <ul className="social mb-0 list-inline mt-8 icon">
                        <div className="black_circle_min">
                          <a href><img src="./img/f.png" alt="" /></a>
                          <a href><img src="./img/i.png" alt="" /></a>
                          <a href><img src="./img/t.png" alt="" /></a>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 wow fadeInLeft">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src="./img/t3.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                      <h6 className="mb-0">Gasiyeva Vasila</h6><span className="small text-uppercase text-muted">Волонтер</span>
                      <ul className="social mb-0 list-inline mt-4 icon">
                        <div className="black_circle_min">
                          <a href><img src="./img/f.png" alt="" /></a>
                          <a href><img src="./img/i.png" alt="" /></a>
                          <a href><img src="./img/t.png" alt="" /></a>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 wow fadeInRight">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src="./img/t4.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                      <h6 className="mb-0">Iskhakova Nargiza</h6><span className="small text-uppercase text-muted">Волонтер</span>
                      <ul className="social mb-0 list-inline mt-4 icon">
                        <div className="black_circle_min">
                          <a href><img src="./img/f.png" alt="" /></a>
                          <a href><img src="./img/i.png" alt="" /></a>
                          <a href><img src="./img/t.png" alt="" /></a>
                        </div>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* FOOTER================================================================ */}
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </div>
    </>
  );
}

export default About;
