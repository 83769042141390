import axios, { baseURL } from "../../axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LangContext } from "../../Contexts/Context";
const OneActivity = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const { lang, setLang } = useContext(LangContext);
  var { id } = useParams();

  useEffect(() => {
    axios
      .get(`news/${id}`)
      .then((res) => {
        setData(res.data.data.news);
        setIsLoading(false);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  }, []);
  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Activity</title>
        <link rel="shortcut icon" href="../img/icon.png" type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Work+Sans:300,400,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.min.css"
        />
        <link rel="stylesheet" href="../../css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../../css/owl.theme.default.min.css" />
        <link rel="stylesheet" href="../../bootstrap/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="../../bootstrap/css/owl.carousel.min.css"
        />
        <link rel="stylesheet" href="../../css/animate.css" />
        <link rel="stylesheet" href="../../css/all.min.css" />
        <link rel="stylesheet" href="../../css/style.css" />
      </head>
      <div id="top">
        <img src="./img/top1.png" alt="" />
      </div>
      <header>
        <main>
          <section className="gallery">
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <div className="card-b">
                <div className="gallery-list">
                  <div className="g-card">
                    <div className="top img-cont">
                      {data &&
                        data.image.map((e, i) => (
                          <img
                            className="gimage bigImage"
                            src={`${baseURL}uploads/${e}`}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="galTextMor ">
                  {" "}
                  <h1 className="wow bounceInDown" id="title_me">
                    {lang == "uz" && data.title_uz}
                    {lang == "ru" && data.title_ru}
                    {lang == "en" && data.title_en}
                  </h1>
                  <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
                    <hr />
                  </div>
                  <p className="l-parag">
                    {lang == "uz" && data.text_uz}
                    {lang == "ru" && data.text_ru}
                    {lang == "en" && data.text_en}
                  </p>
                </div>
              </div>
            )}
          </section>
        </main>
      </header>
    </>
  );
};
export default OneActivity;
