import { useTranslation } from "react-i18next";

const Portners = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Modul</title>
        <link rel="shortcut icon" href="../img/icon.png" type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Work+Sans:300,400,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.min.css"
        />
        <link rel="stylesheet" href="../css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/owl.theme.default.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/bootstrap.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/animate.css" />
        <link rel="stylesheet" href="../css/all.min.css" />
        <link rel="stylesheet" href="../css/style.css" />
      </head>
      <div id="top">
        <img src="../img/top1.png" alt="" />
      </div>
      <header>
        {/* Header ================================== */}
        <header>
          <div>
            <div className="w-100 pt-155 pb-155 blue-layer  opc85 position-relative">
              <div
                className="fixed-bg"
                style={{ backgroundImage: "url(../img/portner.jpg)" }}
              />
              <div className="particles-js" id="prtcl2"></div>
              <div className="container">
                <div className="banner-wrap position-relative text-center w-100">
                  <div className="banner-inner d-inline-block">
                    <h2 className="mb-0 wow zoomIn">{t("partners")}</h2>
                  </div>
                </div>
                {/* Banner Wrap */}
              </div>
            </div>
          </div>
        </header>
        {/* main */}
        <main>
          <section className="section_2">
            <div className="container ">
              <h2 className="text-center wow fadeInDown">{t("ec")}</h2>{" "}
              <div className="w-50 mx-auto">
                <hr />
              </div>
              <section className="vission-mission py-150 rpy-100">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 ul_po">
                      <ul className="vission-tabs">
                        <li
                          className="wow fadeInUp"
                          data-tab="tab_1"
                          data-wow-duration="1.5s"
                        >
                          <h3 style={{ textAlign: "center" }}>{t("u1")}</h3>
                        </li>
                        <li
                          className="active wow fadeInUp"
                          data-tab="tab_2"
                          data-wow-duration="1.5s"
                          data-wow-delay="0.3s"
                        >
                          <h3 style={{ textAlign: "center" }}>{t("u2")}</h3>
                        </li>
                        <li
                          className="wow fadeInUp"
                          data-tab="tab_3"
                          data-wow-duration="1.5s"
                          data-wow-delay="0.5s"
                        >
                          <h3 style={{ textAlign: "center" }}>{t("u3")}</h3>
                        </li>
                        <li
                          className=" wow fadeInUp"
                          data-tab="tab_4"
                          data-wow-duration="1.5s"
                          data-wow-delay="0.3s"
                        >
                          <h3 style={{ textAlign: "center" }}>{t("u4")}</h3>
                        </li>
                        <li
                          className=" wow fadeInUp"
                          data-tab="tab_5"
                          data-wow-duration="1.5s"
                          data-wow-delay="0.3s"
                        >
                          <h3>{t("u5")}</h3>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="vission-content-wrap">
                        <div id="tab_1" className="vission-tab-content">
                          <div className="section-title">
                            <h2>{t("u6")}</h2>
                          </div>
                          <div className="success-item  bg-snow">
                            <div className="icon-image">
                              <img
                                src="../img/img_icons/6.jpg"
                                alt="Success Icon Image"
                              />
                            </div>
                          </div>
                          <p>
                            Трансильванский университет Брашова нацелен на
                            подготовку высококвалифицированных специалистов в
                            области фундаментальных наук, технологий, экономики,
                            права, социологии, искусства и медицины, а также на
                            предоставление знаний и услуг для общества. Таким
                            образом, Университет Трансильвании обеспечивает и
                            развивает ресурсы и инструменты, необходимые для
                            образовательных и научно-исследовательских процессов
                            на уровне, который соответствует требованиям
                            Европейского пространства высшего образования и
                            исследований, определенным Болонской, Копенгагенской
                            и Лиссабонской декларациями и продвигаемым
                            Национальной стратегией. для высшего образования в
                            Румынии. ( читать дальше )
                          </p>
                          <p>
                            UTBV - большой университет с 18 факультетами в
                            различных областях, с сильной междисциплинарной
                            докторской школой практически по всем специальностям
                            университета. Научно-исследовательские навыки
                            являются одним из приоритетов университета, у нас
                            есть много результатов исследований в результате
                            тесного международного сотрудничества, а также между
                            университетом и исследовательским институтом, ICDT -
                            Research &amp; Development Institute. UTBV также
                            участвует во многих европейских исследовательских и
                            образовательных проектах. ( читать дальше )
                          </p>
                          <p>
                            <a
                              href="http://decide.unitbv.ro/"
                              className="btn btn-danger text-white"
                              target="_blank"
                            >
                              Читать дальше....
                            </a>
                          </p>
                        </div>
                        <div id="tab_2" className="vission-tab-content active">
                          <div className="section-title">
                            <h2>{t("u2")}</h2>
                          </div>
                          <div className="success-item  bg-snow">
                            <div className="icon-image">
                              <img
                                src="../img/img_icons/2.jpg"
                                alt="Success Icon Image"
                              />
                            </div>
                          </div>
                          <p>{t("u2-desc")}</p>{" "}
                          <p>
                            <a
                              href="https://library.lit.ie/lit-library-eu-projects/"
                              className="btn btn-danger text-white"
                              target="_blank"
                            >
                              {t("more")}
                            </a>
                          </p>
                        </div>
                        <div id="tab_3" className="vission-tab-content">
                          <div className="section-title">
                            <h2>{t("u3")}</h2>
                          </div>
                          <div className="success-item  bg-snow">
                            <div className="icon-image">
                              <img
                                src="../img/img_icons/3png.png"
                                alt="Success Icon Image"
                              />
                            </div>
                          </div>
                          <p>
                            Middlesex University - это прогрессивный университет
                            в Лондоне, который ставит наших студентов на первое
                            место. Мы гордимся тем, что предоставляем
                            квалифицированное обучение, основанное на
                            вдохновляющих исследованиях и практике. Университету
                            был присужден Серебряный приз за высокое качество
                            преподавания в рамках первой в стране системы
                            педагогического мастерства (TEF). Кроме того, в
                            Миддлсексе сильная исследовательская культура и
                            сообщество преподавателей, которые продолжают
                            укреплять и расширять масштабы своих исследований.
                            Этот проект будет базироваться на факультете
                            компьютерных наук. Этот отдел направлен на то, чтобы
                            вооружить студентов знаниями и навыками,
                            необходимыми для решения реальных проблем. Обучение
                            регулярно достигает высоких оценок удовлетворенности
                            Национальным опросом студентов (NSS). Кафедра
                            является частью факультета науки и технологий,
                            расположенного в Хендоне, на северо-западе Лондона.
                          </p>
                          <p>
                            <a
                              href="https://library.lit.ie/lit-library-eu-projects/"
                              className="btn btn-danger text-white"
                              target="_blank"
                            >
                              Читать дальше....
                            </a>
                          </p>
                        </div>
                        <div id="tab_4" className="vission-tab-content">
                          <div className="section-title">
                            <h2>Технический университет Дрездена (TUD)</h2>
                          </div>
                          <div className="success-item  bg-snow">
                            <div className="icon-image">
                              <img
                                src="../img/img_icons/4.jpg"
                                alt="Success Icon Image"
                              />
                            </div>
                          </div>
                          <p>
                            Технический университет Дрездена (TUD) - один из
                            крупнейших технических университетов и один из
                            ведущих и наиболее динамично развивающихся
                            университетов Германии. Университет с полной учебной
                            программой с 18 факультетами в пяти школах
                            предлагает широкий выбор 121 дисциплины и охватывает
                            широкий спектр исследований. Его направления в
                            области биомедицины, биоинженерии, материаловедения,
                            информационных технологий, микроэлектроники, а также
                            энергетики и окружающей среды считаются образцовыми
                            в Германии и во всей Европе. С 2012 года TUD
                            является одним из немецких «университетов передового
                            опыта». В TUD обучается около 32 400 студентов. На
                            международном уровне TUD заработал хорошую
                            репутацию, и около одной восьмой его студентов
                            приезжают из-за границы. Сегодня около 8,300
                            сотрудников из 70 стран работают в Техническом
                            университете Дрездена.
                          </p>
                          <p>
                            <a
                              href="https://tu-dresden.de/"
                              className="btn btn-danger text-white"
                              target="_blank"
                            >
                              {t("more")}
                            </a>
                          </p>
                        </div>
                        <div id="tab_5" className="vission-tab-content">
                          <div className="section-title">
                            <h2>{t("u6")}</h2>
                          </div>
                          <div className="success-item  bg-snow">
                            <div className="icon-image">
                              <img
                                src="../img/img_icons/5.png"
                                alt="Success Icon Image"
                              />
                            </div>
                          </div>
                          <p>
                            Университет Крита - относительно новое учебное
                            заведение среднего размера в Греции, но уже
                            известное как на национальном, так и на
                            международном уровне своим новаторским подходом к
                            образованию, значительной исследовательской
                            деятельностью и динамичным характером международного
                            сотрудничества. Библиотека сформирована как единое
                            целое - это одно из многих нововведений, внесенных
                            Университетом Крита в греческую академическую среду,
                            - которое позволило ей развиваться экспоненциально и
                            отметить значительные достижения, которые позволили
                            ей стать пионером греческих академических библиотек.
                            Особое внимание уделяется библиотечным сетям и
                            сотрудничеству, о чем свидетельствует значительный
                            вклад библиотеки и ее сотрудников в создание сети
                            греческих академических библиотек HEAL-LINK.
                            Библиотека реализовала ключевые проекты по развитию
                            и дальнейшему совершенствованию своих стандартов
                            качества и ресурсов, обучению персонала, а также
                            увязке библиотечных услуг с учебными программами и
                            местным сообществом. Университет Крита участвовал в
                            различных инновационных библиотечных проектах, таких
                            как проект Tempus 517117 «Развитие информационной
                            грамотности для обучения на протяжении всей жизни и
                            экономики знаний в странах Западных Балкан», который
                            направлен на развитие библиотек и служб
                            информационной грамотности на Западных Балканах.
                          </p>
                          <p>
                            <a
                              href="https://www.lib.uoc.gr/info/projects/?lang=en"
                              className="btn btn-danger text-white"
                              target="_blank"
                            >
                              {t("more")}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section_2">
                <div className="container">
                  <h2 className="text-center wow fadeInDown">
                    {t("partner-uz")}
                  </h2>{" "}
                  <div className="w-50 mx-auto">
                    <hr />
                  </div>
                  <section className="vission-mission py-150 rpy-100">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 ul_po">
                          <ul className="vission-tabs">
                            <li
                              className="wow fadeInUp"
                              data-tab="tab_6"
                              data-wow-duration="1.5s"
                            >
                              <h3 style={{ textAlign: "center" }}>{t("u8")}</h3>
                            </li>
                            <li
                              className="active wow fadeInUp"
                              data-tab="tab_7"
                              data-wow-duration="1.5s"
                              data-wow-delay="0.3s"
                            >
                              <h3 style={{ textAlign: "center" }}>{t("u7")}</h3>
                            </li>
                            <li
                              className="wow fadeInUp"
                              data-tab="tab_8"
                              data-wow-duration="1.5s"
                              data-wow-delay="0.5s"
                            >
                              <h3 style={{ textAlign: "center" }}>{t("u9")}</h3>
                            </li>
                            <li
                              className=" wow fadeInUp"
                              data-tab="tab_9"
                              data-wow-duration="1.5s"
                              data-wow-delay="0.3s"
                            >
                              <h3 style={{ textAlign: "center" }}>
                                {t("u10")}
                              </h3>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <div className="vission-content-wrap">
                            <div id="tab_6" className="vission-tab-content">
                              <div className="section-title">
                                <h2>{t("u8")}</h2>
                              </div>
                              <div className="success-item  bg-snow">
                                <div className="icon-image">
                                  <img
                                    src="../img/img_icons/6.png"
                                    alt="Success Icon Image"
                                  />
                                </div>
                              </div>
                              <p>
                                SIES была создана в 2004 году на базе
                                Самаркандского кооперативного института. SIES -
                                уникальное учреждение, которое готовит кадры для
                                сферы услуг в Узбекистане. Кроме того, Институт
                                готовит кадры в области туризма, гостиничного
                                бизнеса, экономики, маркетинга, менеджмента,
                                обслуживания, финансов и страхования,
                                банковского дела и бухгалтерского учета. В
                                настоящее время в институте 3 факультета, 14
                                кафедр и кафедра магистратуры. В настоящее время
                                в обучении участвуют более 2500 бакалавров и
                                более 66 магистрантов и докторантов. Институт
                                очень активно налаживает сотрудничество с
                                университетами со всего мира, чтобы получить
                                новые навыки и способности, изучать опыт
                                европейских университетов, делиться знаниями и
                                внедрять его в Узбекистане. Кроме того, в 2018
                                году институтом подписаны соглашения между
                                Казанским федеральным университетом (Россия),
                                Полоцкому государственному университету
                                (Беларусь) и Международному институту
                                гостиничного менеджмента (Индия) за создание
                                дипломов двойной степени для студентов и
                                магистрантов в области международного
                                гостиничного менеджмента, экономики,
                                менеджмента, бухгалтерского учета и финансов.
                                Кроме того, при НИС «SamISI Sayyoh plus»
                                действует туристическое агентство. У студентов
                                есть возможность применить свои теоретические
                                знания на практике, пока они будут обучаться у
                                специалистов туристического агентства.
                              </p>
                              <p>
                                <a
                                  href="chrome-extension://fheoggkfdfchfphceeifdbepaooicaho/html/site_status_block_page.html"
                                  className="btn btn-danger text-white"
                                  target="_blank"
                                >
                                  Читать дальше....
                                </a>
                              </p>
                            </div>
                            <div
                              id="tab_7"
                              className="vission-tab-content active"
                            >
                              <div className="section-title">
                                <h2>{t("u7")}</h2>
                              </div>
                              <div className="success-item  bg-snow">
                                <div className="icon-image">
                                  <img
                                    src="../img/img_icons/7.jpg"
                                    alt="Success Icon Image"
                                  />
                                </div>
                              </div>
                              <p>{t("u7-desc")}</p>
                              <p>
                                <a
                                  href="https://tuit.uz/en/developing-services-for-individuals-with-disabilities-decide"
                                  className="btn btn-danger text-white"
                                  target="_blank"
                                >
                                  {t("more")}
                                </a>
                              </p>
                            </div>
                            <div id="tab_8" className="vission-tab-content">
                              <div className="section-title">
                                <h2>
                                  Каракалпакский государственный университет
                                  (КГУ)
                                </h2>
                              </div>
                              <div className="success-item  bg-snow">
                                <div className="icon-image">
                                  <img
                                    src="../img/img_icons/8.png"
                                    alt="Success Icon Image"
                                  />
                                </div>
                              </div>
                              <p>
                                Каракалпакский государственный университет (КГУ)
                                - единственный государственный университет в
                                Каракалпакстане, расположенный в
                                западно-северной части Узбекистана. KSU,
                                основанный в 1976 году, является крупнейшим
                                образовательным институтом в области социальных
                                наук и смежных областей и унаследовал миссию
                                служить обществу. Есть 10 факультетов и 42
                                кафедры, которые предлагают широкие возможности
                                для многопрофильных исследований для 9 000
                                студентов.
                              </p>
                              <p>
                                Факультеты университета готовят специалистов в
                                различных областях и предлагают бакалавриат и
                                магистра в области права, гуманитарных наук,
                                экономики, химии и технологических наук,
                                филологических наук (каракалпакский, узбекский,
                                туркменский, казахский, русский, английский,
                                немецкий языки, перевод, журналистика. )
                                Математика, физика, архитектура, естественные
                                науки, инженерия, профессиональное образование
                                (педагогический факультет), спорт и музыка. С
                                2018 года начали готовить кадры и по переписке.
                              </p>
                              <p>
                                Кафедра магистратуры КГУ была открыта в 2011
                                году. На кафедре магистратуры обучаются более
                                130 студентов, которые сейчас учатся в
                                магистратуре по многим специальностям. Есть
                                специальности английского, русского и тюркского
                                языков.
                              </p>
                              <p>
                                <a
                                  href="http://karsu.uz/en/decide/"
                                  className="btn btn-danger text-white"
                                  target="_blank"
                                >
                                  Читать дальше....
                                </a>
                              </p>
                            </div>
                            <div id="tab_9" className="vission-tab-content">
                              <div className="section-title">
                                <h2>
                                  Общество детей-инвалидов города Ташкента
                                  МУШТОК КО'ЗЛАР
                                </h2>
                              </div>
                              <div className="success-item  bg-snow">
                                <div className="icon-image">
                                  <img
                                    src="../img/img_icons/9.png"
                                    alt="Success Icon Image"
                                  />
                                </div>
                              </div>
                              <p>
                                Общество детей-инвалидов города Ташкента
                                «MUSHTOQ KO'ZLAR» было утверждено в
                                государственном реестре и заверено Министерством
                                юстиции Республики Узбекистан и Ташкентским
                                городским управлением юстиции 19 июня 2017 года.
                                Общество начало свою работу по 1 июля 2017 года.
                                Общество помогает детям с ограниченными
                                возможностями решать их экономические и
                                социальные проблемы, организует
                                благотворительные акции, предоставляет им
                                образование, профессиональную подготовку,
                                занятия физическими упражнениями и спортом,
                                поддерживает их в достижении поставленных целей.
                                Общество также организовало курсы английского и
                                русского языков для детей с ограниченными
                                возможностями; обучающие курсы, семинары для
                                матерей детей-инвалидов. Члены общества также
                                посещают детские дома-инвалиды, чтобы помочь им
                                в учебе.
                              </p>
                              <p>
                                <a
                                  href="http://mushtoqkozlar.uz/erasmus/"
                                  className="btn btn-danger text-white"
                                  target="_blank"
                                >
                                  Читать дальше....
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="section_2">
                    <div className="container">
                      <h2 className="text-center wow fadeInDown">
                        {t("partner-kz")}
                      </h2>{" "}
                      <div className="w-50 mx-auto">
                        <hr />
                      </div>
                      <section className="vission-mission py-150 rpy-100">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-6 ul_po">
                              <ul className="vission-tabs">
                                <li
                                  className="wow fadeInUp"
                                  data-tab="tab_10"
                                  data-wow-duration="1.5s"
                                >
                                  <h3 style={{ textAlign: "center" }}>
                                    {t("u11")}
                                  </h3>
                                </li>
                                <li
                                  className="active wow fadeInUp"
                                  data-tab="tab_11"
                                  data-wow-duration="1.5s"
                                  data-wow-delay="0.3s"
                                >
                                  <h3 style={{ textAlign: "center" }}>
                                    {t("u12")}
                                  </h3>
                                </li>
                                <li
                                  className="wow fadeInUp"
                                  data-tab="tab_12"
                                  data-wow-duration="1.5s"
                                  data-wow-delay="0.5s"
                                >
                                  <h3 style={{ textAlign: "center" }}>
                                    {t("u13")}
                                  </h3>
                                </li>
                                <li
                                  className=" wow fadeInUp"
                                  data-tab="tab_13"
                                  data-wow-duration="1.5s"
                                  data-wow-delay="0.3s"
                                >
                                  <h3 style={{ textAlign: "center" }}>
                                    {t("u14")}
                                  </h3>
                                </li>
                                <li
                                  className=" wow fadeInUp"
                                  data-tab="tab_14"
                                  data-wow-duration="1.5s"
                                  data-wow-delay="0.3s"
                                >
                                  <h3 style={{ textAlign: "center" }}>
                                    {t("u15")}
                                  </h3>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <div className="vission-content-wrap">
                                <div
                                  id="tab_10"
                                  className="vission-tab-content"
                                >
                                  <div className="section-title">
                                    <h2>{t("u15")}</h2>
                                  </div>
                                  <div className="success-item  bg-snow">
                                    <div className="icon-image">
                                      <img
                                        src="../img/img_icons/9.jpg"
                                        alt="Success Icon Image"
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    SКазахский агротехнический университет имени
                                    Сакена Сейфуллина (далее - КАТУ), основанный
                                    в 1957 году, является крупнейшим вузом
                                    агротехнического профиля в Республике
                                    Казахстан, имеющим более чем 60-летнюю
                                    историю, а также первым вузом в Нур-Султане.{" "}
                                  </p>
                                  <p>
                                    В КАТУ 8 факультетов, 42 кафедры, в том
                                    числе военная кафедра, и 24
                                    научно-исследовательских института и центра,
                                    включая многоязычное обучение на казахском,
                                    русском и английском языках по 65
                                    специальностям. Общее количество студентов
                                    КАТУ составляет 13 150 человек. На
                                    факультете работают 889 преподавателей, в
                                    том числе 83 доктора наук, 372 кандидата
                                    наук и 58 кандидатов наук.
                                  </p>
                                  <p>
                                    Подготовка специалистов осуществляется по 90
                                    образовательным программам (EP) по 36
                                    специальностям BA, 31 MA и 23 PhD, в том
                                    числе два диплома по IT EP вместе с
                                    Миланским университетом (Италия) и
                                    Agromanagement EP с университетом
                                    Weinstephan-Triesdorf (Германия), и 7 EP на
                                    английском языке.
                                  </p>
                                  <p>
                                    <a
                                      href="https://kazatu.edu.kz/"
                                      className="btn btn-danger text-white"
                                      target="_blank"
                                    >
                                      Читать дальше....
                                    </a>
                                  </p>
                                </div>
                                <div
                                  id="tab_11"
                                  className="vission-tab-content active"
                                >
                                  <div className="section-title">
                                    <h2>{t("u12")}</h2>
                                  </div>
                                  <div className="success-item  bg-snow">
                                    <div className="icon-image">
                                      <img
                                        src="../img/img_icons/11.jpg"
                                        alt="Success Icon Image"
                                      />
                                    </div>
                                  </div>
                                  <p>{t("u12-desc")}</p>
                                  <p>
                                    <a
                                      href="https://www.kaznu.kz/"
                                      className="btn btn-danger text-white"
                                      target="_blank"
                                    >
                                      {t("more")}
                                    </a>
                                  </p>
                                </div>
                                <div
                                  id="tab_12"
                                  className="vission-tab-content"
                                >
                                  <div className="section-title">
                                    <h2>
                                      Костанайский областной университет им.
                                      А.Байтурсынова
                                    </h2>
                                  </div>
                                  <div className="success-item  bg-snow">
                                    <div className="icon-image">
                                      <img
                                        src="../img/img_icons/12.png"
                                        alt="Success Icon Image"
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    Костанайский государственный университет им.
                                    А.Байтурсынова (КГУ) - ведущий
                                    учебно-научно-методический центр северной
                                    части страны. В его состав входят 7
                                    факультетов с 34 программами бакалавриата,
                                    28 магистерскими программами и 17
                                    программами докторантуры. КГУ - один из
                                    десяти университетов Казахстана, участвующих
                                    в Национальной программе
                                    индустриально-инновационного развития.
                                    Университет является членом Европейской
                                    ассоциации университетов. Деятельность
                                    университета направлена ​​на удовлетворение
                                    потребностей регионального развития через
                                    повышение качества исследований и
                                    образования.
                                  </p>
                                  <p>
                                    <a
                                      href="https://ksu.edu.kz/en/partnership/mezhdunarodnye_proekty_programmy_erasmus_kgu_im_a_bajtursynova/razvitie_uslug_dlya_lic_s_ogranichennymi_vozmozhnostyami_decide/"
                                      className="btn btn-danger text-white"
                                      target="_blank"
                                    >
                                      Читать дальше....
                                    </a>
                                  </p>
                                </div>
                                <div
                                  id="tab_13"
                                  className="vission-tab-content"
                                >
                                  <div className="section-title">
                                    <h2>
                                      Академия государственного управления при
                                      Президенте Республики Казахстан
                                    </h2>
                                  </div>
                                  <div className="success-item  bg-snow">
                                    <div className="icon-image">
                                      <img
                                        src="../img/img_icons/9.png"
                                        alt="Success Icon Image"
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    Академия государственного управления при
                                    Президенте Республики Казахстан -
                                    единственное высшее учебное заведение в
                                    стране, которое осуществляет обучение,
                                    переподготовку и повышение квалификации
                                    государственных служащих.
                                  </p>{" "}
                                  <p>НАШ ВЗГЛЯД:</p>
                                  <li>
                                    Интеллектуальная площадка для эффективного
                                    руководства и профессиональных менеджеров.
                                  </li>
                                  <p />
                                  <p>НАША МИССИЯ:</p>{" "}
                                  <li>
                                    Создавать, сохранять и распространять знания
                                    на благо страны и мира.
                                  </li>
                                  <p />
                                  <p>НАШИ ЦЕННОСТИ:</p>
                                  <li>Честность</li> <li>Профессионализм</li>{" "}
                                  <li>Инновации</li>
                                  <li>Открытость</li>
                                  <li>Любить мир вокруг</li>
                                  <p />
                                  <p>
                                    <a
                                      href="https://www.apa.kz/en/international-partnership/я"
                                      className="btn btn-danger text-white"
                                      target="_blank"
                                    >
                                      Читать дальше....
                                    </a>
                                  </p>
                                </div>
                                <div
                                  id="tab_14"
                                  className="vission-tab-content"
                                >
                                  <div className="section-title">
                                    <h2>
                                      Доступное образование для студентов с
                                      ограниченными возможностями
                                    </h2>
                                  </div>
                                  <div className="success-item  bg-snow">
                                    <div className="icon-image">
                                      <img
                                        src="../img/img_icons/14.png"
                                        alt="Success Icon Image"
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    Доступное образование для студентов с
                                    ограниченными возможностями основано в
                                    октябре 2004 года по инициативе 29
                                    студентов-инвалидов и преподавателей кафедры
                                    психологии КГУ им. А.Байтурсынова. Миссия
                                    ассоциации - обеспечение социальной
                                    интеграции людей с ограниченными
                                    возможностями, гуманизация общественного
                                    сознания и продвижение демократического
                                    плюрализма по отношению к людям с
                                    ограниченными возможностями. В результате
                                    широкого партнерства с органами
                                    исполнительной власти, бизнес-структур и
                                    других неправительственных организаций, при
                                    активной поддержке волонтеров и СМИ 389
                                    студентов-инвалидов получили высшее или
                                    среднее профессиональное образование. Среди
                                    выпускников 4 магистра, 1 кандидат наук, 1
                                    докторант.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="section_2">
                        <div className="container">
                          <h2 className="text-center wow fadeInDown">
                            {t("partner-tj")}
                          </h2>{" "}
                          <div className="w-50 mx-auto">
                            <hr />
                          </div>
                          <section className="vission-mission py-150 rpy-100">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 ul_po">
                                  <ul className="vission-tabs">
                                    <li
                                      className="wow fadeInUp"
                                      data-tab="tab_15"
                                      data-wow-duration="1.5s"
                                    >
                                      <h3 style={{ textAlign: "center" }}>
                                        {t("u16")}
                                      </h3>
                                    </li>
                                    <li
                                      className="active wow fadeInUp"
                                      data-tab="tab_16"
                                      data-wow-duration="1.5s"
                                      data-wow-delay="0.3s"
                                    >
                                      <h3 style={{ textAlign: "center" }}>
                                        {t("u17")}
                                      </h3>
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-tab="tab_17"
                                      data-wow-duration="1.5s"
                                      data-wow-delay="0.5s"
                                    >
                                      <h3 style={{ textAlign: "center" }}>
                                        {t("u18")}
                                      </h3>
                                    </li>
                                    <li
                                      className=" wow fadeInUp"
                                      data-tab="tab_18"
                                      data-wow-duration="1.5s"
                                      data-wow-delay="0.3s"
                                    >
                                      <h3 style={{ textAlign: "center" }}>
                                        {t("u19")}
                                      </h3>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-6">
                                  <div className="vission-content-wrap">
                                    <div
                                      id="tab_15"
                                      className="vission-tab-content"
                                    >
                                      <div className="section-title">
                                        <h2>{t("u20")}</h2>
                                      </div>
                                      <div className="success-item  bg-snow">
                                        <div className="icon-image">
                                          <img
                                            src="../img/img_icons/15.png"
                                            alt="Success Icon Image"
                                          />
                                        </div>
                                      </div>
                                      <p>
                                        Худжандский государственный университет
                                        (КГУ), носящий имя одного из выдающихся
                                        людей таджикского народа, Героя
                                        Таджикистана, академика Бободжона
                                        Гафурова, считается крупнейшим
                                        образовательным и научным центром
                                        республики. За почти 90 лет своего
                                        существования университет внес
                                        значительный вклад в духовный рост
                                        таджикского народа и продвижение науки и
                                        культуры в стране, подготовив более 80
                                        тысяч специалистов для различных
                                        отраслей образования и других отраслей
                                        народного хозяйства. . В университете 17
                                        факультетов, на которых обучаются более
                                        20 тысяч студентов. Университет имеет 76
                                        кафедр, 4 исследовательских центра,
                                        современную техническую базу и учебные
                                        ресурсы, в которых работают более 1000
                                        преподавателей. Более 50% из них имеют
                                        научные звания доктора философии. и
                                        докторские степени. KSU намеревается
                                        быть глобально связанным университетом,
                                        развивая двусторонние отношения с
                                        передовыми мировыми университетами. Наш
                                        университет подписал двусторонние
                                        контракты и меморандумы о
                                        взаимопонимании с 35 странами. В
                                        университете обучаются более 700
                                        иностранных студентов из 12 стран. За
                                        последнее десятилетие университет
                                        участвовал в 20 проектах, финансируемых
                                        ЕС, в рамках программ ERASMUS MUNDUS,
                                        TEMPUS и ERASMUS PLUS и считается вторым
                                        лучшим университетом по количеству
                                        проектов ЕС и успешной их реализации.
                                        Все это позволяет все большему
                                        количеству университетских ученых
                                        принимать активное участие в
                                        международных конференциях, симпозиумах,
                                        семинарах и т. Д. С целью формирования
                                        интеллектуального капитала нашего
                                        университета и роста нашей национальной
                                        экономики.
                                      </p>
                                      <p>
                                        <a
                                          href="https://hgu.tj/international-program/project/erasmus--decide-developing-services-for-individuals-with-disabilities.html"
                                          className="btn btn-danger text-white"
                                          target="_blank"
                                        >
                                          Читать дальше....
                                        </a>
                                      </p>
                                    </div>
                                    <div
                                      id="tab_16"
                                      className="vission-tab-content active"
                                    ></div>
                                    <div
                                      id="tab_17"
                                      className="vission-tab-content active"
                                    >
                                      <div className="section-title">
                                        <h2>{t("u17")}</h2>
                                      </div>
                                      <div className="success-item  bg-snow">
                                        <div className="icon-image">
                                          <img
                                            src="../img/img_icons/17.png"
                                            alt="Success Icon Image"
                                          />
                                        </div>
                                      </div>
                                      <p>{t("u17-desc")}</p>
                                      <p>
                                        <a
                                          href="https://hgu.tj/international-program/project/erasmus--decide-developing-services-for-individuals-with-disabilities.html"
                                          className="btn btn-danger text-white"
                                          target="_blank"
                                        >
                                          {t("more")}
                                        </a>
                                      </p>
                                    </div>
                                    <div
                                      id="tab_18"
                                      className="vission-tab-content"
                                    >
                                      <div className="section-title">
                                        <h2>
                                          Общественная организация родителей
                                          детей с ограниченными возможностями и
                                          специалистов «Rushdi Inclusia»
                                        </h2>
                                      </div>
                                      <div className="success-item  bg-snow">
                                        <div className="icon-image">
                                          <img
                                            src="../img/img_icons/9.png"
                                            alt="Success Icon Image"
                                          />
                                        </div>
                                      </div>
                                      <p>
                                        Общественная организация родителей детей
                                        с ограниченными возможностями и
                                        специалистов «Рушди (развивай)
                                        инклюзия», Душанбе, Таджикистан была
                                        создана в августе 2011 года, но работает
                                        по инициативе родителей с сентября 2007
                                        года. Гражданская общественная
                                        организация родителей с детьми с
                                        ограниченными возможностями и
                                        специалистов «Рушди Инклюсиа» и партнеры
                                        ответили на запрос Министерства
                                        образования и науки о продолжении
                                        продвижения инклюзивного образования в
                                        Таджикистане. За 6 лет при
                                        финансировании ЮНИСЕФ, OSI, AAR Japan и
                                        более мелких доноров «Rushdi Inclusia»
                                        разработала модель поддержки детей с
                                        ограниченными возможностями для
                                        получения равного образования в
                                        общеобразовательной школе и
                                        предотвращения их помещения в
                                        специализированные учреждения. «Rushdi
                                        Inclusia» также приобрела опыт в
                                        инклюзивном образовании, поддерживая
                                        родительские организации в продвижении
                                        инклюзивного образования и поддерживая
                                        учителей в создании инклюзивной среды в
                                        школах и дошкольных учреждениях. Обладая
                                        сильным организационным потенциалом,
                                        «Рушди Инклюсиа» принимала участие в
                                        наращивании потенциала и формировании
                                        команды Национальной Родительской
                                        Коалиции в Таджикистане.
                                      </p>{" "}
                                      <p>
                                        <span style={{ fontWeight: "bold" }}>
                                          Миссия:
                                        </span>{" "}
                                        «ОБЕСПЕЧИТЬ ДОСТОЙНУЮ ЖИЗНЬ
                                        ДЕТЯМ-ИНВАЛИДАМ И ИХ СЕМЬЯМ»
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: "bold" }}>
                                          Видение:{" "}
                                        </span>
                                        Содействовать развитию инклюзивного
                                        образования и достижению равного доступа
                                        к образованию в Таджикистане посредством
                                        реализации образовательных, социальных
                                        программ и программ по правам человека
                                        для детей и молодежи.
                                      </p>{" "}
                                      <p>
                                        Деятельность «Рушди инклюзия» направлена
                                        ​​на удовлетворение потребностей детей и
                                        молодых людей с ограниченными
                                        возможностями в образовании и
                                        всестороннем развитии.
                                      </p>{" "}
                                      <p>
                                        <span style={{ fontWeight: "bold" }}>
                                          Приоритеты:
                                        </span>
                                      </p>
                                      <ol type={1} style={{ color: "black" }}>
                                        <li>
                                          1. Программы для раннего развития
                                          детей с особыми образовательными
                                          потребностями.
                                        </li>
                                        <li>
                                          2. Содействовать развитию потенциала
                                          специалистов в области инклюзивного
                                          образования.
                                        </li>{" "}
                                        <li>
                                          3. 1Поддержка в социализации и
                                          обучении, приобретении
                                          профессиональных навыков детей с
                                          особыми образовательными потребностями
                                          в образовательных учреждениях.
                                        </li>
                                      </ol>{" "}
                                      <p>
                                        <a
                                          href="https://www.facebook.com/Rushdiinclusia"
                                          className="btn btn-danger text-white"
                                          target="_blank"
                                        >
                                          Читать дальше....
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </main>
        {/* FOOTER================================================================ */}
      </header>
    </div>
  );
};
export default Portners;
