import { useTranslation } from "react-i18next";
import module1File from '../../files/modul-1.rar'
import module5File from '../../files/modul-5.rar'
import lm15 from '../../files/List-of-Module-1-5.pdf'
import lm1 from '../../files/List-of-Module-1.pdf'
import mefm1 from '../../files/Member-Evaluation-form-Modul-1.pdf'
import mefm5 from '../../files/Member-Evaluation-form-Modul-5.pdf'
import m1p from '../../files/Module-1-Promrt-Mushtoq-kozlar.pdf'
import m5p from '../../files/Module-5-Promrt-Mushtoq-kozlar.pdf'


const Modul = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Modul</title>
        <link rel="shortcut icon" href="../img/icon.png" type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Work+Sans:300,400,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.min.css"
        />
        <link rel="stylesheet" href="../css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/owl.theme.default.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/bootstrap.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/animate.css" />
        <link rel="stylesheet" href="../css/all.min.css" />
        <link rel="stylesheet" href="../css/style.css" />
      </head>
      <div id="top">
        <img src="../img/top1.png" alt="" />
      </div>
      <header>
        {/* Header ================================== */}
        <header>
          <div>
            <div className="w-100 pt-155 pb-155 blue-layer  opc85 position-relative">
              <div
                className="fixed-bg"
                style={{ backgroundImage: "url(../img/modool.jpg)" }}
              />
              <div className="particles-js" id="prtcl2"></div>
              <div className="container">
                <div className="banner-wrap position-relative text-center w-100">
                  <div className="banner-inner d-inline-block">
                    <h2 className="mb-0 wow zoomIn">{t("module")} </h2>
                  </div>
                </div>
                {/* Banner Wrap */}
              </div>
            </div>
          </div>
        </header>
        {/* main */}
        <main>
          {/* Модуль ===================== */}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <p className="purpos wow fadeInLeft">{t("m-decide")}</p>
                  <p className="wow fadeInRight" style={{ marginTop: "1px" }}>
                    {t("m-achieve")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="section_4" style={{ marginBottom: "260px" }}>
            <div className="tabbed wow zoomIn">
              <input type="radio" name="tabs" id="tab-nav-1" defaultChecked />
              <label htmlFor="tab-nav-1">{t("module")} 1:</label>
              <input type="radio" name="tabs" id="tab-nav-2" />
              <label htmlFor="tab-nav-2">{t("module")} 2:</label>
              <input type="radio" name="tabs" id="tab-nav-3" />
              <label htmlFor="tab-nav-3">{t("module")} 3:</label>
              <input type="radio" name="tabs" id="tab-nav-4" />
              <label htmlFor="tab-nav-4">{t("module")} 4:</label>
              <input type="radio" name="tabs" id="tab-nav-5" />
              <label htmlFor="tab-nav-5">{t("module")} 5:</label>
              <input type="radio" name="tabs" id="tab-nav-6" />
              <label htmlFor="tab-nav-6">{t("module")} 6:</label>
              <input type="radio" name="tabs" id="tab-nav-7" />
              <label htmlFor="tab-nav-7">{t("module")} 7:</label>
              <input type="radio" name="tabs" id="tab-nav-8" />
              <label htmlFor="tab-nav-8">{t("module")} 8:</label>
              <div className="tabs">
                <div>
                  <h2>{t("m1-title")}</h2>
                  <p>{t("m1-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-1-REFERENCE-.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-1-DESCRIPTION-ESP.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r2")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-1-WEEKLY-AIMS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r3")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-1-DETAILED-LESSON-PLANS-RECORD-FOR-TRAINERS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r4")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={module1File} download>
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('module-1-file')}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={lm1} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('l-m-1').toUpperCase()}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={mefm1} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('m-e-f-m-1').toUpperCase()}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={m1p} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('m-1-p').toUpperCase()}
                      </p>
                    </a>
                  </div>
                  
                </div>
                <div>
                  <h2>{t("m2-title")}</h2>
                  <p>{t("m2-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="hhttp://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-2-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r5")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-2-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r5")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-2-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r6")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-2-WEEKLY-AIMS-CHECKLIST.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("d-m-r7")}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m3-title")}</h2>
                  <p>{t("m3-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/11/DECIDE-MODULE-3-1-DETAILED-MODULE-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("dmr8")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/11/DECIDE-MODULE-3-2-WEEKLY-AIMS-CHECKLIST-RECORD-FOR-TRAINERS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("dmr9")}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m4-title")}</h2>
                  <p>{t("m4-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-1-_Links-for-the-Module-4.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm10")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-2_Materials-for-DLOs.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm11")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-Module-4_Optional_Teach.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm12")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-Module-4_Weekly_Aims_P6_P10.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm13")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/MODULE-4-Teaching-Record-P6.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm14")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/MODULE-4-Teaching-Record-P6.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm15")}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m5-title")}</h2>
                  <p>{t("m5-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/5-MODULE-DESCRIPTION-1.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm15")} (1)
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/5-MODULE-DESCRIPTION-2.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm15")} (2)
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/5-MODULE-DESCRIPTION-2.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm15")} (2)
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/5-MODULE-DESCRIPTION-3.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm15")} (3)
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-1-Quesntionnaire-for-Representatives-of-Higher-or-Further-Education-module-5.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm16")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/LIST-of-teachers-enrolled-in-Erasmus-module-5.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm17")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="htthttp://www.decideproject.ie/wp-content/uploads/2020/10/Module-5-WEEKLY-AIMS-CHECKLIST-RECORD-FOR-TRAINERS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm18")}
                      </p>
                    </a>
                  </div>
                  {/* <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Pilot-One-and-Two-Prompts-30th-Jan-2020-module-5.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm19")}
                      </p>
                    </a>
                  </div> */}
                  <div className="text-center wow fadeInUp">
                    <a href={module5File} download>
                      <p className="linkDoc" style={{ color: "white" }}>
                       {t("module-5-file")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={lm15} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('l-m-5').toUpperCase()}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={mefm5} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('m-e-f-m-5').toUpperCase()}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href={m5p} >
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t('m-5-p').toUpperCase()}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m6-title")}</h2>
                  <p>{t("m6-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/09/DECIDE-Module-6-Description-and-topics.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm20")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/09/DECIDE-Module-6_LIST-OF-REFERENCES.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm21")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-1-WEEKLY-AIMS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm22")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/09/DECIDE-MODULE-6_DETAILED-MODULE-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm23")}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m7-title")}</h2>
                  <p>{t("m7-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-1.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm24")} 1
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-2.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm24")} 2
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-3.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm24")} 3
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-4.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm24")} 4
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-5.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm24")} 5
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-6.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm24")} 6
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="htthdecideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_DETAILED-MODULE-DESCRIPTION.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm25")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_WEEKLY-AIMS-CHECKLIST-RECORD-FOR-TRAINERS.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm26")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDECIDE-MODULE-7_OPTIONAL-TEACHING-RECORD-TEMPLATE.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm27")}
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <h2>{t("m8-title")}</h2>
                  <p>{t("m8-desc")}</p>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/1-DETAILED-MODULE-DESCRIPTION-8-module.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm28")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/2-OPTIONAL-DECIDE-MODULE-TEACHING-RECORD-TEMPLATE-8-module.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm29")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/DECIDE-MODULE-7_Appendix-3.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm30")}
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="hthttp://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-1-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 1 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-2-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {" "}
                        {t("drm31")} 2 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-3-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 3 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-4-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 4 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-5-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 5 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="http://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-6-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 6 {t("module")} 8
                      </p>
                    </a>
                  </div>
                  <div className="text-center wow fadeInUp">
                    <a href="hhttp://www.decideproject.ie/wp-content/uploads/2020/10/Appendix-7-Module-8.pdf">
                      <p className="linkDoc" style={{ color: "white" }}>
                        {t("drm31")} 7 {t("module")} 8
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* FOOTER================================================================ */}
        {/* <div id="loader-wrapper">
            <div id="loader" />
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
          </div> */}
      </header>
    </div>
  );
};
export default Modul;
