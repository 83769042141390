import i18n from "i18next";
import { useContext } from "react";
import { initReactI18next } from "react-i18next";
import { LangContext } from "../Contexts/Context";

i18n.use(initReactI18next).init({
  fallbackLng: "uz",
  lng: "uz",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    ru: {
      translations: require("./locales/ru/translations.json"),
    },
    uz: {
      translations: require("./locales/uz/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "uz", "ru"];

export default i18n;
