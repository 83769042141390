import { useTranslation } from "react-i18next";

const Purpose = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Modul</title>
        <link rel="shortcut icon" href="../img/icon.png" type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Work+Sans:300,400,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.min.css"
        />
        <link rel="stylesheet" href="../css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/owl.theme.default.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/bootstrap.min.css" />
        <link rel="stylesheet" href="../bootstrap/css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../css/animate.css" />
        <link rel="stylesheet" href="../css/all.min.css" />
        <link rel="stylesheet" href="../css/style.css" />
      </head>
      <div id="top">
        <img src="../img/top1.png" alt="" />
      </div>
      <header>
        {/* Header ================================== */}
        <header>
          <div>
            <div className="w-100 pt-155 pb-155 blue-layer  opc85 position-relative">
              <div
                className="fixed-bg"
                style={{ backgroundImage: "url(../img/project.jpg)" }}
              />
              <div className="particles-js" id="prtcl2"></div>
              <div className="container">
                <div className="banner-wrap position-relative text-center w-100">
                  <div className="banner-inner d-inline-block">
                    <h2 className="mb-0 wow zoomIn">
                      {t("main-aim")}
                      <span style={{ color: "red" }}>DECIDE</span>{" "}
                    </h2>
                  </div>
                </div>
                {/* Banner Wrap */}
              </div>
            </div>
          </div>
        </header>
        {/* main */}
        <main>
          <div className="container section_3">
            <div className="row">
              <div className="col-lg-6 wow fadeInLeft">
                <p className="mb-5" style={{ marginTop: "60px" }}>
                  <img
                    src="../img/DECIDE-local-partners-Cooperation-1.jpg"
                    alt="Image"
                    className="img-fluid rounded"
                  />
                </p>
              </div>
              <div className="col-lg-5 ml-auto  ">
                <h2 className="site-section-heading text-black mb-3 wow fadeInDown">
                  {t("main-aim")}
                </h2>
                <p className="text-black wow fadeInRight"> </p>
                <p className="purpose wow fadeInRight">{t("aim-desc1")}</p>
                <p />
              </div>
            </div>
          </div>
        </main>
        {/* FOOTER================================================================ */}
      </header>
    </div>
  );
};
export default Purpose;
