import React, { createContext, useState } from "react";

export const LangContext = createContext();

export const ProviderContext = ({ children }) => {
  const [lang, setLang] = useState("uz");
  const [lan, setLan] = useState("");
  const [locale, setLocale] = useState("uz");
  return (
    <LangContext.Provider
      value={{ lang, setLang, lan, setLan, locale, setLocale }}
    >
      {children}
    </LangContext.Provider>
  );
};
