import Logo from "./icon.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer>
        <div className="w-100 blue-layer  opc85 position-relative">
          <div
            className="fixed-bg"
            style={{
              backgroundImage: "url(../img/project.jpg)",
              backgroundAttachment: "fixed",
            }}
          />
          <div className="particles-js" id="prtcl3"></div>
          <div className="bg_footer">
            <div className="footer_title">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="logofoot text-center m-auto wow fadeInLeft">
                      <img src={Logo} alt="Error" />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="leftfoot wow fadeInLeft">
                      <h5 className="contacts">{t("contact")}</h5>
                      <p style={{ color: "rgb(143, 142, 142)" }}>
                        <i className="fas fa-home" />
                        {t("address")}
                        <a href="tel:+998 97 700 54 01 ">
                          +998 97 700 54 01{" "}
                        </a>{" "}
                        <br />
                        <a href="mailto:shatemirovashahnoza@gmail.com">
                          shatemirovashahnoza@gmail.com
                        </a>
                        <br />
                        <a className="tg-link" target={'_blank'} href="https://t.me/mushtoqkozla">
                          <span  >
                           <svg style={{width: '20px', height: '20px', overflow: 'hidden' , objectFit: 'contain', color: 'white'}} fill={'currentColor'} viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg" class="icon icon__telegram"><path d="M20.4341 0.483897L1.16865 7.91463C-0.146251 8.4422 -0.138452 9.17559 0.928979 9.50262L5.73385 11.0023L7.5723 16.6389C7.79581 17.2558 7.68563 17.5005 8.33346 17.5005C8.83341 17.5005 9.05519 17.2725 9.33364 17.0005C9.51071 16.8273 10.562 15.8051 11.7359 14.6638L16.7339 18.3565C17.6536 18.8639 18.3177 18.601 18.5467 17.5023L21.8274 2.04205C22.1633 0.695381 21.3141 0.0845408 20.4341 0.483897ZM6.48792 10.6578L17.3181 3.82491C17.8588 3.49703 18.3545 3.67331 17.9475 4.03461L8.67401 12.4016L8.31293 16.2528L6.48792 10.6578Z"></path></svg>
                          </span>
                           Telegram
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="rightfoot">
                      <h5 className="info">{t("geo")}</h5>
                      <iframe
                        className="wow fadeInRight"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5995.712952278106!2d69.328908!3d41.290228!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef570ebe8e38d%3A0xedde4f9636ba0cfa!2z0YPQu9C40YbQsCDQkNC70LjQvNC60LXQvdGCLCDQotCw0YjQutC10L3Rgiwg0KPQt9Cx0LXQutC40YHRgtCw0L0!5e0!3m2!1sru!2sus!4v1613800822353!5m2!1sru!2sus"
                        width="70%"
                        height={120}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="footer">
         <div class="black_circle_min">
            <a href=""><img src="../img/facebook.png" alt="" /></a>
            <a href=""><img src="../img/instagram.png" alt="" /></a>
            <a href=""><img src="../img/telegram.png" alt="" /></a>
          </div>
        </div> */}
          </div>
        </div>
      </footer>
      {/* SOCIAl=============================================================== */}
      <footer className="site-footer" style={{ background: "rgb(60, 61, 60)" }}>
        <div className="container">
          <div className="row  text-center">
            <div className="col-12 pt-2">
              <p style={{ color: "#fff" }} className="footer">
                {t("copyright")}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
