import Logo from "./icon.png";
import { useTranslation } from "react-i18next";
import { useContext, useRef, useState, useEffect } from "react";
import { LangContext } from "../Contexts/Context";
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { lang, setLang } = useContext(LangContext);
  const { locale, setLocale } = useContext(LangContext);

  
  // const { lang, setLang } = useContext(LangContext);
  // console.log(lang);
  const localValue = localStorage.getItem("lang")
  let newRef = useRef();
  function changeLanguage(e) {
    setLang(e.target.value);
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(localStorage.getItem("lang"));
  }
  console.log(newRef.value);

  useEffect(() => {
    if (localStorage.getItem("lang") == "uz") {
      newRef.value = "Uzbek";
      setLocale('uz')
    } else if (localStorage.getItem("lang") == "ru") {
      newRef.value = "Russian";
      setLocale('ru')
    } else if (localStorage.getItem("lang") == "en") {
      newRef.value = "English";
      setLocale("en");
    }
  }, [localValue]);
  console.log(newRef)
  return (
    <>
      <div id="top">
        <img src="./img/top1.png" alt="" />
      </div>
      <header id="glavnoe">
        <nav className="navbar position-fixed navbar-expand-lg navbar-light">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={Logo} alt="logo img" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ml-lg-auto">
                <ul className="nav-ul">
                  <li>
                    <a href="/" className="lang" id="main">
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="lang" id="about">
                      {t("about")}
                    </a>
                  </li>
                  <li>
                    <a href="/galery" className="lang " id="gallery">
                      {t("galery")}
                    </a>
                  </li>
                  <li>
                    <a href="/sponsor" className="lang " id="sponsor">
                      {t("sponsors")}
                    </a>
                  </li>
                  <li>
                    <a href="/erasmus" className="lang " id="erasmus">
                      {t("erasmus")}
                    </a>
                  </li>
                  <li>
                    <a href="/contact" className="lang " id="contacts">
                      {t("contact")}
                    </a>
                  </li>
                  <li>
                    <select
                      ref={newRef}
                      onChange={changeLanguage}
                      class="form-select"
                      aria-label="Default select example"
                      className="lang-select"
                      value={locale}
                    >
                      <option value="uz">Uzbek</option>
                      <option value="en">English</option>
                      <option value="ru">Russian</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div id="ytWidget" className="lang" />
            </div>
          </div>
        </nav>
        {/* Слайдер в headere ==================== */}

        {/* Резерв Слайдер ===================================== */}
        {/* 
       <div class="swiper-container wow flipInX" id="glavnoe">
    <div class="swiper-wrapper">
      <div class="swiper-slide" style="background-image:url(./img/mg-4961-1600x1067.jpg)"></div>
      <div class="swiper-slide" style="background-image:url(./img/slider_bg2.jpeg)"></div>
      <div class="swiper-slide" style="background-image:url(./img/slider_bg3.jpeg)"></div>
      <div class="swiper-slide" style="background-image:url(./img/slider_bg4jpeg.jpeg)"></div>
      <div class="swiper-slide" style="background-image:url(./img/slider_bg5.jpeg)"></div>
    </div>
      </div> */}
      </header>
    </>
  );
};
export default Navbar;
