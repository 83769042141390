import axios, { baseURL } from "../axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import OneGallery from "./onePage/oneGallery";
import { LangContext } from "../Contexts/Context";
const Galery = () => {
  const { t, i18n } = useTranslation();
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const { lang, setLang } = useContext(LangContext);
  console.log(lang);
  console.log(galleries);
  useEffect(() => {
    axios
      .get("galery")
      .then((res) => {
        setGalleries(res.data.data.gallery);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  }, []);
  return (
    <div>
      <div id="top">
        <img src="./img/top1.png" alt="" />
      </div>
      <header>
        <main>
          <section className="secText">
            <h3 className="wow fadeInDown lang" id="gallery1">
              {t("galery")}
            </h3>
            <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
              <hr />
            </div>
            <p className="wow fadeInUp">{t("g-p-quote")}</p>
          </section>
          <div className="gallery-wrapper">
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <div className="ones_container">
                <div className="container_card">
                  {galleries.map((item, index) => {
                    return (
                      <div className="card_galer wow fadeInLeft">
                        {/* <div className="circle_galer1" style={{background: `url('${baseURL}uploads/${item.image[0]}') 50% 50% / cover;`}}>
               </div> */}
                        <img
                          src={`${baseURL}uploads/${item.image[0]}`}
                          className="circle_galer1"
                        />
                        <div className="content_galer">
                          <p>{item.updated_at}</p>
                          <p style={{ color: "black", fontWeight: "bold" }}>
                            {lang == "uz" && item.text_uz}
                            {lang == "ru" && item.text_ru}
                            {lang == "en" && item.text_en}
                          </p>
                          <Link to={`${item._id}`}>{t("more")}</Link>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="card_galer wow fadeInLeft">
                  <div className="circle_galer1">
                  </div>
                  <div className="content_galer">
                    <p>03.12.2020</p>
                    <p style={{color: 'black', fontWeight: 'bold'}}>Международный день Инвалидов!</p>
                    <a href="./galereya/mejdunarod.html">Далее</a>
                  </div>
                </div>
                <div className="card_galer wow fadeInDown">
                  <div className="circle_galer2">
                  </div>
                  <div className="content_galer">
                    <p>27.10.2020</p>
                    <p style={{color: 'black', fontWeight: 'bold'}}>Прогулка в Зоопарк</p>
                    <a href="./galereya/zoopark.html">Далее</a>
                  </div>
                </div>
                <div className="card_galer wow fadeInRight">
                  <div className="circle_galer3">
                  </div>
                  <div className="content_galer">
                    <p>21.03.2020</p>
                    <p style={{color: 'black', fontWeight: 'bold'}}>Международный праздник Навруз</p>
                    <a href="./galereya/navruz.html">Далее</a>
                  </div>
                </div>
                <div className="card_galer wow fadeInDown">
                  <div className="circle_galer2" style={{background: 'url(./img/rolik.jpg) 50% 50% / cover', height: '210px'}}>
                  </div>
                  <div className="content_galer">
                    <p style={{color: 'black', fontWeight: 'bold'}}>Ролики мироприятий</p>
                    <a href="./galereya/video.html">Далее</a>
                  </div>
                </div> */}
                </div>
              </div>
            )}
          </div>
        </main>
        {/* FOOTER================================================================ */}
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </header>
    </div>
  );
};
export default Galery;
