import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangContext } from "../Contexts/Context";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid green",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const Contact = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { locale, setLocale } = useContext(LangContext);
  const { lang } = useContext(LangContext);
  console.log(lang);
  const { t, i18n } = useTranslation();
  const formBtn = (e) => {
    e.preventDefault();
    if (
      e.target[0].value.length > 0 &&
      e.target[1].value.length > 0 &&
      e.target[2].value.length > 0
    ) {
      handleOpen();
      let botMessege = `
Salom Mushtoqko'z Admin, Yangi Xabar!😊%0A
Ismi    👤: ${e.target[0].value}%0A
Email   📧: ${e.target[1].value}%0A 
Telefon 📞: ${e.target[2].value}%0A
Xabar   📰: ${e.target[3].value}%0A              

                `;

      let url = `https://api.telegram.org/bot5612177735:AAGFm_j-KX_Urwr5gj90ibl2gOEYkhXdxUc/sendMessage?chat_id=-806647123&text=${botMessege}`;
      async function fetchAsync(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
      }
      fetchAsync(url);
      e.target[0].value = "";

      e.target[1].value = "";

      e.target[2].value = "";

      e.target[3].value = "";
    } else {
      return -1;
    }
  };

  return (
    <>
      <head>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
          crossorigin="anonymous"
        ></link>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3"
          crossorigin="anonymous"
        ></script>
      </head>

      <main>
        <section className="section_contacts  ">
          <div
            className="py-5 bg-light site-section bg-image sp"
            style={{
              backgroundImage: 'url("./img/contact.jpg")',
              backgroundAttachment: "fixed",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-8 mb-5">
                  <form
                    onSubmit={formBtn}
                    action="./mail/mail.php"
                    method="post"
                    className="p-5 wow fadeInLeft"
                  >
                    <div className="row form-group">
                      <div className="col-md-12 mb-3 mb-md-0">
                        <label
                          className="font-weight-bold wow fadeInLeft"
                          htmlFor="fullname"
                        >
                          {t("name")}
                        </label>
                        <input
                          type="text"
                          id="fullname"
                          className="form-control"
                          name="name"
                          placeholder={t("name")}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12">
                        <label
                          className="font-weight-bold wow fadeInLeft"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12 mb-3 mb-md-0">
                        <label
                          className="font-weight-bold wow fadeInLeft"
                          htmlFor="phone"
                        >
                          {t("tel")}
                        </label>
                        <input
                          type="phone"
                          id="phone"
                          className="form-control"
                          name="phone"
                          placeholder={t("tel")}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12">
                        <label
                          className="font-weight-bold wow fadeInLeft"
                          htmlFor="message"
                        >
                          {t("message")}
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          cols={30}
                          rows={5}
                          className="form-control"
                          placeholder={t("t-message")}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12 wow fadeInLeft">
                        <input
                          type="submit"
                          defaultValue="Отправить"
                          className="btn b text-black px-4 py-2"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 wow fadeInRight">
                  <div className="p-4 mb-3 f">
                    <h3 className="h5 text-black mb-3 wow fadeInRight">
                      {t("con-info")}
                    </h3>
                    <p className="mb-0 font-weight-bold wow fadeInRight">
                      {t("t-address")}
                    </p>
                    <p className="mb-4 wow fadeInRight">{t("address")}</p>
                    <p className="mb-0 font-weight-bold wow fadeInRight">
                      {t("tel")}
                    </p>
                    <p className="mb-4 tel wow fadeInRight a">
                      <a href="tel:+998 97 700 54 01">+998 97 700 54 01 </a>
                    </p>
                    <p className="mb-0 font-weight-bold wow fadeInRight">
                      Email
                    </p>
                    <p className="mb-0 tel wow fadeInRight a">
                      <a href="mailto:shatemirovashahnoza@gmail.com">
                        shatemirovashahnoza@gmail.com
                      </a>
                    </p>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} id="modal-me">
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          style={{
                            color: "green",
                            fontSize: "35px",
                            lineHeight: "45px",
                          }}
                        >
                          {locale == "uz" && "Xabar jo'natildi!"}
                          {locale == "ru" && "Сообщение отправлено!"}
                          {locale == "en" && "Message has been sent!"}
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2 }}
                          style={{ fontSize: "20px" }}
                        >
                          {locale == "en" &&
                            "We will contact with you as soon as possible."}{" "}
                          {locale == "ru" &&
                            "Мы свяжемся с вами как можно скорее."}
                          {locale == "uz" &&
                            "Biz imkon qadar tezroq siz bilan bog'lanamiz."}
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 cont_top ">
            <h2 className="text-center text-black wow fadeInDown">
              {t("location")}
            </h2>
            <div className="w-50 mx-auto">
              <hr />
            </div>
          </div>
        </section>
        <iframe
          className="wow zoomIn"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5995.712952278106!2d69.328908!3d41.290228!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef570ebe8e38d%3A0xedde4f9636ba0cfa!2z0YPQu9C40YbQsCDQkNC70LjQvNC60LXQvdGCLCDQotCw0YjQutC10L3Rgiwg0KPQt9Cx0LXQutC40YHRgtCw0L0!5e0!3m2!1sru!2sus!4v1613800822353!5m2!1sru!2sus"
          width="100%"
          height={500}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
      </main>
    </>
  );
};
export default Contact;
