import axios, { baseURL } from "../../axios";
import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { LangContext } from "../../Contexts/Context";
import { Link } from "react-router-dom";

const Activities = () => {
  const [data, setData] = useState([{ image: [""] }]);
  const lang = localStorage.getItem("lang");
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    axios
      .get(`news`)
      .then((res) => {
        console.log(res.data.data.news);
        setData(res.data.data.news);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  }, []);
  return (
    <div>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Modul</title>
        <link rel="shortcut icon" href="../img/icon.png" type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Work+Sans:300,400,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.min.css"
        />
        <link rel="stylesheet" href="../../css/owl.carousel.min.css" />
        <link rel="stylesheet" href="../../css/owl.theme.default.min.css" />
        <link rel="stylesheet" href="../../bootstrap/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="../../bootstrap/css/owl.carousel.min.css"
        />
        <link rel="stylesheet" href="../../css/animate.css" />
        <link rel="stylesheet" href="../../css/all.min.css" />
        <link rel="stylesheet" href="../../css/style.css" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
          crossorigin="anonymous"
        ></link>
        <script
          src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
          integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.min.js"
          integrity="sha384-IDwe1+LCz02ROU9k972gdyvl+AESN10+x7tBKgc9I5HFtuNz0wWnPclzo6p9vxnk"
          crossorigin="anonymous"
        ></script>
      </head>
      <div id="top">
        <img src="../img/top1.png" alt="" />
      </div>
      <header>
        {/* Header ================================== */}
        <header>
          <div>
            <div className="w-100 pt-155 pb-155 blue-layer  opc85 position-relative">
              <div
                className="fixed-bg"
                style={{ backgroundImage: "url(../img/activities.jpg)" }}
              />
              <div className="particles-js" id="prtcl2"></div>
              <div className="container">
                <div className="banner-wrap position-relative text-center w-100">
                  <div className="banner-inner d-inline-block">
                    <h2 className="mb-0 wow zoomIn">{t("event")} </h2>
                  </div>
                </div>
                {/* Banner Wrap */}
              </div>
            </div>
          </div>
        </header>
        {/* main */}
        <main>
          {/*  */}
          {/*  */}
          <section>
            <div className="container spin-cont">
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                <div className="row row-activity">
                  {data &&
                    data.map((e) => (
                      <div className="col-md-4 blog_box" id="box-wrap">
                        <a href="#" className="mask wow zoomIn">
                          <img
                            src={`${baseURL}uploads/${e.image[0]}`}
                            className="img-responsive zoom-img"
                          />
                        </a>
                        <h3 className="wow fadeInLeft">
                          <a href="#">
                            {lang == "uz" &&
                              `${e.title_uz.substring(0, 100)}...`}
                            {lang == "en" &&
                              `${e.title_en.substring(0, 100)}...`}
                            {lang == "ru" &&
                              `${e.title_ru.substring(0, 100)}...`}
                          </a>
                        </h3>{" "}
                        <br />
                        <div className="links">
                          <ul className="icon_text wow fadeInUp">
                            <li>
                              <img
                                src="../img/img_icons/calendar.png"
                                width="40px"
                                height="40px"
                                alt=""
                              />
                              <span>{e.date}</span>
                            </li>
                          </ul>
                        </div>
                        <p className="wow zoomIn">
                          {lang == "uz" && `${e.text_uz.substring(0, 150)}...`}
                          {lang == "en" && `${e.text_en.substring(0, 150)}...`}
                          {lang == "ru" && `${e.text_ru.substring(0, 150)}...`}
                        </p>
                        <div className="btn_muv wow fadeInRight pb-5 active_btn">
                          <div className="btn_2">
                            <a
                              href="../activities/1sentyabr.html"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                textAlign: "left",
                              }}
                            >
                              <Link
                                onClick={() => window.scrollTo({ top: 0 })}
                                to={`${e._id}`}
                              >
                                {t("more")}
                              </Link>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </section>
        </main>
        {/* FOOTER================================================================ */}
      </header>
    </div>
  );
};
export default Activities;
