import { Swiper, SwiperSlide } from "swiper/react";
import axios, { baseURL } from "../axios";
import { useTranslation } from "react-i18next";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import { useEffect, useState } from "react";
const Erasmus = () => {
  const { t, i18n } = useTranslation();
  const [portners, setportners] = useState([]);
  useEffect(() => {
    axios
      .get("partners")
      .then((res) => {
        setportners(res.data.data.Partners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div id="top">
        <img src="./img/top1.png" alt="" />
      </div>
      <header>
        <section>
          <div className="w-100  opc85 position-relative">
            <div
              className="fixed-bg"
              style={{
                background: 'url("./img/erasmus_bg.jpg") 50% 50% / cover',
              }}
            >
              {/* standard logo */}
              <div className="text-center pt-4 wow fadeInDownBig">
                <img
                  src="./img/logoeras.png"
                  width={185}
                  height={78}
                  alt="My CMS Logo"
                  data-retina_logo_url
                  className="fusion-standard-logo"
                />
              </div>
            </div>
            <div className="particles-js" id="prtcl2"></div>
            <div className="word text-center">
              <div className="container wow zoomIn">
                <span>E</span>
                <span>R</span>
                <span>A</span>
                <span>S</span>
                <span>M</span>
                <span>U</span>
                <span>S</span>
                <span>+</span>
              </div>
            </div>
          </div>
        </section>
      </header>
      <main className="erss">
        {/* flip-card-container */}
        <div
          className="flip-card-container wow fadeInLeft"
          style={{ hue: 350 }}
        >
          <div className="flip-card">
            <div className="card-front">
              <figure>
                <div className="img-bg1" />
                <img src="./img/portner.jpg" alt="Brohm Lake" />
                <figcaption>{t("sponsors")}</figcaption>
              </figure>
            </div>
            <div className="card-back">
              {/* only if the image is necessary */}
              <figure>
                <div className="img-bg1" />
                <img src="./img/portner.jpg" alt="Brohm Lake" />
              </figure>
              <button className="button_ers">
                <a href="/erasmus/portners">{t("more")}</a>{" "}
              </button>
              <div className="design-container">
                <span className="design design--1" />
                <span className="design design--2" />
                <span className="design design--3" />
                <span className="design design--4" />
                <span className="design design--5" />
                <span className="design design--6" />
                <span className="design design--7" />
                <span className="design design--8" />
              </div>
            </div>
          </div>
        </div>
        {/* flip-card-container */}
        <div
          className="flip-card-container wow fadeInRight"
          style={{ hue: 220 }}
        >
          <div className="flip-card">
            <div className="card-front">
              <figure>
                <div className="img-bg1" />
                <img src="./img/activities.jpg" alt="Brohm Lake" />
                <figcaption>{t("event")}</figcaption>
              </figure>
            </div>
            <div className="card-back">
              <figure>
                <div className="img-bg1" />
                <img src="./img/activities.jpg" alt="Brohm Lake" />
              </figure>
              <button className="button_ers">
                <a href="/erasmus/activities"> {t("more")}</a>{" "}
              </button>
              <div className="design-container">
                <span className="design design--1" />
                <span className="design design--2" />
                <span className="design design--3" />
                <span className="design design--4" />
                <span className="design design--5" />
                <span className="design design--6" />
                <span className="design design--7" />
                <span className="design design--8" />
              </div>
            </div>
          </div>
        </div>
        {/* /flip-card-container */}
        {/* flip-card-container */}
        <div
          className="flip-card-container wow fadeInLeft"
          style={{ hue: 350 }}
        >
          <div className="flip-card">
            <div className="card-front">
              <figure>
                <div className="img-bg1" />
                <img src="./img/modool.jpg" alt="Brohm Lake" />
                <figcaption>{t("module")}</figcaption>
              </figure>
            </div>
            <div className="card-back">
              {/* only if the image is necessary */}
              <figure>
                <div className="img-bg1" />
                <img src="./img/modool.jpg" alt="Brohm Lake" />
              </figure>
              <button className="button_ers">
                <a href="/erasmus/modul">{t("more")}</a>{" "}
              </button>
              <div className="design-container">
                <span className="design design--1" />
                <span className="design design--2" />
                <span className="design design--3" />
                <span className="design design--4" />
                <span className="design design--5" />
                <span className="design design--6" />
                <span className="design design--7" />
                <span className="design design--8" />
              </div>
            </div>
          </div>
        </div>
        {/* /flip-card-container */}
        {/* flip-card-container */}
        <div
          className="flip-card-container wow fadeInRight"
          style={{ hue: 220 }}
        >
          <div className="flip-card">
            <div className="card-front">
              <figure>
                <div className="img-bg1" />
                <img src="./img/project.jpg" alt="Brohm Lake" />
                <figcaption>{t("about-p-aim")}</figcaption>
              </figure>
            </div>
            <div className="card-back">
              <figure>
                <div className="img-bg1" />
                <img src="./img/project.jpg" alt="Brohm Lake" />
              </figure>
              <button className="button_ers">
                <a href="/erasmus/purpose">{t("more")}</a>{" "}
              </button>
              <div className="design-container">
                <span className="design design--1" />
                <span className="design design--2" />
                <span className="design design--3" />
                <span className="design design--4" />
                <span className="design design--5" />
                <span className="design design--6" />
                <span className="design design--7" />
                <span className="design design--8" />
              </div>
            </div>
          </div>
        </div>
        {/* /flip-card-container */}
        {/* flip-card-container */}
        <div
          className="flip-card-container wow fadeInLeft"
          style={{ hue: 170 }}
        >
          <div className="flip-card">
            <div className="card-front">
              <figure>
                <div className="img-bg1" />
                <img src="./img/project_2.jpg" alt="Image 2" />
                <figcaption>{t("task")}</figcaption>
              </figure>
            </div>
            <div className="card-back">
              <figure>
                <div className="img-bg1" />
                <img src="./img/project_2.jpg" alt="image-2" />
              </figure>
              <button className="button_ers">
                <a href="/erasmus/task">{t("more")}</a>{" "}
              </button>
              <div className="design-container">
                <span className="design design--1" />
                <span className="design design--2" />
                <span className="design design--3" />
                <span className="design design--4" />
                <span className="design design--5" />
                <span className="design design--6" />
                <span className="design design--7" />
                <span className="design design--8" />
              </div>
            </div>
          </div>
        </div>
        {/* /flip-card-container */}
      </main>
      <div className="container">
        <h2 className="text-center wow zoomIn">
          {t("partners")}{" "}
          <div className="w-50 mx-auto">
            <hr />
          </div>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              526: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              930: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            // navigation
            tag="section"
            wrapperTag="ul"
            id="main"
            loop={true}
            spaceBetween={30}
            autoplay={{ stopOnLastSlide: false, delay: 1000 }}
            slidesPerView={4}
            settings={{
              height: "80vh",
              slidingDuration: 600,
              slidingDelay: 100,
              shouldAutoplay: true,
            }}
          >
            {portners.map((partner, index) => {
              return (
                <SwiperSlide>
                  <div className="slider">
                    <img
                      src={`${baseURL}uploads/${partner.image}`}
                      style={{ borderRadius: "100%", height: "150px" }}
                      alt="partner"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <div className="slide"><img src="./img/img_icons/ic1.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic2.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic3.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic4.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic5.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic1.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic2.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic3.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic4.jpg" /></div>
              <div className="slide"><img src="./img/img_icons/ic5.jpg" /></div> */}
        </h2>
      </div>
      {/* FOOTER================================================================ */}
      <div id="loader-wrapper">
        <div id="loader" />
        <div className="loader-section section-left" />
        <div className="loader-section section-right" />
      </div>
    </div>
  );
};
export default Erasmus;
