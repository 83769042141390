import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useContext, useEffect, useState } from "react";
import axios, { baseURL } from "../axios";
import { useTranslation } from "react-i18next";
import { LangContext } from "../Contexts/Context";

const Index = () => {
  const { t, i18n } = useTranslation();
  const [sliders, setSliders] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { lang, setLang } = useContext(LangContext);
  const cssClasses = ["una", "dolo", "tele", "una", "dolo", "tele"];
  useEffect(() => {
    axios
      .get("sliders")
      .then((res) => {
        setSliders(res.data.data.Slider);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("galery")
      .then((res) => {
        setGalleries(res.data.data.gallery);
        console.log(galleries);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  }, []);
  return (
    <>
      <div>
        <div className="slider_button">
          <section className="galeria">
            <input
              type="radio"
              id="uno"
              defaultValue={1}
              name="tractor"
              defaultChecked="checked"
            />
            <input type="radio" id="dole" defaultValue={2} name="tractor" />
            <input type="radio" id="tele" defaultValue={3} name="tractor" />
            {sliders.map((slider, index) => {
              return (
                <article
                  className={`card  ${cssClasses[index]}`}
                  style={{
                    backgroundImage: `url("http://mushtoqkozlar.uz/css/AAAsSAAALEgHS3X78AAAAFElEQVR4nGNhYGBwYIET////ZwAAETIDym2lzEcAAAAASUVORK5CYII="),  url('${baseURL}uploads/${slider.image}')`,
                  }}
                >
                  <label
                    htmlFor={`${cssClasses[1]}`}
                    className="entypo-left-bold otra"
                  >
                    <i className="fas fa-angle-double-left" />
                  </label>
                  <label
                    htmlFor={`${cssClasses[2]}`}
                    className="entypo-right-bold otra"
                  >
                    <i className="fas fa-angle-double-right" />
                  </label>
                  <label
                    htmlFor={`${cssClasses[3]}`}
                    className="entypo-arrows-ccw afin"
                  />
                </article>
              );
            })}
            <article className="card una">
                <label htmlFor="dole" className="entypo-left-bold otra"><i className="fas fa-angle-double-left" /></label>
                <label htmlFor="tele" className="entypo-left-bold otra"><i className="fas fa-angle-double-right" /></label>
                <label htmlFor="uno" className="entypo-arrows-ccw afin" />
              </article>
              <article className="card dos">
                <label htmlFor="tele" className="entypo-left-bold otra"><i className="fas fa-angle-double-left" /></label>
                <label htmlFor="uno" className="entypo-left-bold otra"><i className="fas fa-angle-double-right" /></label>
                <label htmlFor="dole" className="entypo-arrows-ccw afin" />
              </article>
              <article className="card tres">
                <label htmlFor="uno" className="entypo-left-bold otra"><i className="fas fa-angle-double-left" /></label>
                <label htmlFor="dole" className="entypo-right-bold otra"><i className="fas fa-angle-double-right" /></label>
              </article>
          </section>
        </div>
        <main>
          {/* О нас ================================================================== */}
          <section className="slider_up_section" id="o_nas">
            <div className="about-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="text-center wow fadeInDown lang" id="about2">
                      {t("about")}
                    </h2>
                    <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
                      <hr />
                    </div>
                  </div>

                  <div className="col-lg-5 ml-auto mx-auto about wow fadeInLeft">
                    <p className>
                      <img
                        src="./img/baut.jpg"
                        alt="Image"
                        className="img-fluid"
                      />
                    </p>
                  </div>

                  <div className="col-lg-5">
                    <p className="wow fadeInRight lang" id="mushtoq">
                      {" "}
                      <span style={{ color: "red" }}>
                        {t("about-desc-title")}
                      </span>
                      {t("about-desc")}
                    </p>
                    <div className="btn_muv  wow fadeInRight ">
                      <div className="btn_2 lang">
                        <a
                          href="./about.html"
                          style={{
                            textDecoration: "none",
                            color: "white",
                            textAlign: "center",
                          }}
                          id="chitaty"
                        >
                          {t("more")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Галерея ============================================== */}
          <section className="section_2" id="galereya">
            <div className="about-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="text-center wow fadeInDown lang " id="galer">
                      Галерея
                    </h2>
                    <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
                      <hr />
                    </div>
                  </div>
                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      526: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      930: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                      1280: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                      },
                      1400: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    // navigation
                    tag="section"
                    wrapperTag="ul"
                    id="main"
                    loop={true}
                    spaceBetween={30}
                    autoplay={{ stopOnLastSlide: false, delay: 1000 }}
                    slidesPerView={4}
                    settings={{
                      height: "80vh",
                      slidingDuration: 600,
                      slidingDelay: 100,
                      shouldAutoplay: true,
                    }}
                  >
                    {galleries.map((gallery, index) => {
                      return (
                        <SwiperSlide>
                          <div className="img_galer ">
                            <img
                              src={`${baseURL}uploads/${gallery.image[0]}`}
                              alt="Image"
                              className="img-fluid img-gallery"
                            />
                            <div className="text_galer lang">
                              <p className="lang" id="mejdu">
                                {gallery.updated_at}
                              </p>
                              <h3 className="lang" id="mejdu2">
                                {lang == "uz" && gallery.text_uz}
                                {lang == "en" && gallery.text_en}
                                {lang == "ru" && gallery.text_ru}
                              </h3>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* <div className="img_galer wow fadeInDown">
                      <img src="./img/zoo/11.jpg" alt="Image" className="img-fluid" />
                      <div className="text_galer lang ">
                        <p className="lang" id="zoo">27.10.2020</p>
                        <h3 className="lang" id="zoo2">Зоопарк</h3>
                      </div>
                    </div> */}
                  {/* <div className="img_galer wow fadeInLeft">
                      <img src="./img/zoo/8.jpg" alt="Image" className="img-fluid" />
                      <div className="text_galer">
                        <p className="lang" id="navruz">27.10.2020</p>
                        <h3 className="lang" id="navruz2">Навруз</h3>
                      </div>
                    </div>
                    <div className="img_galer wow fadeInRight">
                      <img src="./img/mg-4961-1600x1067.jpg" alt="Image" className="img-fluid" />
                      <div className="text_galer lang">
                        <p className="lang" id="mejdu1"> 03.12.2020</p>
                        <h3 className="lang" id="mejdu3">ОсновМеждународный день инвалидов!</h3>
                      </div>
                    </div>
                    <div className="img_galer wow fadeInDown">
                      <img src="./img/zoo/11.jpg" alt="Image" className="img-fluid" />
                      <div className="text_galer lang ">
                        <p className="lang" id="zoo1">27.10.2020</p>
                        <h3 className="lang" id="zoo3">Зоопарк</h3>
                      </div>
                    </div>
                    <div className="img_galer wow fadeInLeft">
                      <img src="./img/zoo/8.jpg" alt="Image" className="img-fluid" />
                      <div className="text_galer">
                        <p className="lang" id="navruz1">27.10.2020</p>
                        <h3 className="lang" id="navruz3">Навруз</h3>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="btn_muv wow fadeInUpBig">
              <div className="btn_2 lang">
                <a
                  href="/galery"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    textAlign: "center",
                  }}
                  id="gallery1"
                >
                  Галерея
                </a>
              </div>
            </div>
          </section>
          {/* Спонсоры */}
          <section className="section_3" id="nashi_sponsori">
            <div className="about-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 wow fadeInDown">
                    <h2 className="text-center lang" id="sponsor1">
                      {t("sponsors")}
                    </h2>
                    <div className="w-50 mx-auto" style={{ marginTop: "20px" }}>
                      <hr />
                    </div>
                  </div>
                  <div className="col-lg-5 ml-auto mx-auto wow fadeInLeft">
                    <p>
                      <img
                        src="./img/help.png"
                        alt="Image"
                        className="img-fluid"
                      />
                    </p>
                  </div>
                  <div className="col-lg-6 wow fadeInRight">
                    <p className="lang" id="blago">
                      <span style={{ color: "red" }}>
                        {t("sponsors-desc-title")}-
                      </span>
                      {t("sponsors-desc")}
                    </p>
                    <div className="btn_muv wow fadeInRight">
                      <div className="btn_2 lang" id="chitaty1">
                        <a
                          href="./sponsor.html"
                          style={{
                            textDecoration: "none",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {t("more")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* FOOTER================================================================ */}
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </div>
    </>
  );
};
export default Index;
